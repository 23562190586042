<template>
  <div>
    <SrNav></SrNav>
    <!-- 分类 -->
    <div class="warp">
      <div class="course-fl1 mt50">
        <a
          :class="typeIndex == index ? 'active' : ''"
          v-for="(i, index) in typeList"
          @click="getTab(i.article_type_id, index)"
          >{{ i.name }}</a
        >
      </div>
    </div>

    <div class="warp">
      <!-- 面包屑 -->
      <div class="xun1_mbx">
        <div class="xun1_mbx_nr">
          <i></i>
          <div class="xun1_mbx_a">
            <router-link to="/">
              <a href="">首页 -</a>
            </router-link>
            <a href="" class="xun1_mbx_on">考试计划</a>
          </div>
        </div>
      </div>
    </div>

    <!-- 考试计划列表 -->
    <div class="warp clearfix mt30">
      <div class="xun_plan">
        <div class="xun_plan_l">
          <div class="xun_plan_list">
            <ul v-if="KaoShiList.length > 0">
              <li v-for="i in KaoShiList" @click="goDetail(i.article_id)">
                <a>
                  <div class="xun_p_l">
                    <h6>{{ i.name }}</h6>
                    <p>开始报名时间：{{ i.startTime }}至{{ i.endTime }}</p>
                  </div>
                  <div class="xun_p_r" v-if="i.days >= 0">
                    <img src="@/assets/img/time1.png" />
                    <span>仅剩{{ i.days == 0 ? 1 : i.days }}天</span>
                  </div>
                  <div class="xun_p_r" v-else>
                    <img src="@/assets/img/time1.png" />
                    <span>已过期</span>
                  </div>
                </a>
              </li>
            </ul>
            <div class="zanwu" v-else>
              <img src="@/assets/img/zanwu.png" alt="" />
              暂无数据
            </div>
          </div>
          <!-- 页码 -->
          <SrPaginate
            style="margin: 0 auto; text-align: center; margin-top: 50px"
            :current="pagination.current_page"
            :total="pagination.total"
            :pageSize="pagination.page_size"
            @change="changePage"
            v-if="KaoShiList.length > 0"
          ></SrPaginate>
        </div>
        <div class="xun_plan_r">
          <!-- <a v-for="(i,index) in imgList" v-if="index <=3" @click="openCourse(i)"><img :src="i.goods.image.url" />
            <p>{{i.goods.name}}</p>
          </a> -->
          <a v-for="(i,index) in imgList" v-if="i.status == 1" @click="openCourse(i)"><img :src="i.image.url" /></a>
        </div>
      </div>
    </div>
    <SrFooter></SrFooter>
  </div>
</template>
<script>
import SrPaginate from "@/components/SrPaginate";
import SrNav from "@/layout/SrNav";
import SrFooter from "@/layout/SrFooter";
import { article, newType, advertising, recommendModular } from "@/api/index";
export default {
  name: "planList",
  components: {
    SrNav,
    SrFooter,
    SrPaginate,
  },
  data() {
    return {
      KaoShiList: [],
      typeList: [],
      typeIndex: 0,
      pagination: {
        page: 1,
        page_size: 10,
      },
      imgList: [],
      article_type_id: "",
    };
  },
  methods: {
    loadList() {
      let _this = this;
      article({
        type: 1,
        article_type_id: _this.article_type_id,
        ..._this.pagination,
      }).then((res) => {
        console.log(res, "13213");
        var data = res.result.items;
        var time = Date.parse(new Date());
        if (data.length > 0) {
          data.map(function (n) {
            n.startTime = n.start_at.substring(0, 10);
            n.endTime = n.end_at.substring(0, 10);
            var endTime = Date.parse(new Date(n.end_at));
            n.days = _this.intervalTime(time / 1000, endTime / 1000);
          });
        }
        _this.KaoShiList = data;
        _this.pagination = res.result.pagination;
      });
    },
    loadImg() {
      let _this = this;
      advertising({ location: 1 }).then((res) => {
        console.log(res, "广告位");
        if (res.status == 200) {
          _this.imgList = res.result;
        }
      });
    },
    changePage(page, page_size) {
      this.pagination.page = page;
      this.loadList();
    },
    getTab(id, index) {
      this.article_type_id = id;
      this.typeIndex = index;
      this.loadList();
    },
    recommendModular() {
      let _this = this;
      recommendModular().then((res) => {
        var arr = [];
          var list = res.result.data;
          if(list.length > 0){
            list.map((n)=>{
              if(n.about.length > 0){
                n.about.map((s)=>{
                  arr.push(s)
                })
              }
            })
          }
          console.log(arr,'arrrrr')
          _this.imgList = arr;
      });
    },
    goDetail(id) {
      this.$router.push({
        path: "/new/detail",
        query: { article_id: id, type: 1 },
      });
    },
    openCourse(item){
      if(item.url){
        window.open(item.url)
      }
    },
    // openCourse(item){
    //     if (item.goods.type == 1) {
    //     this.$router.push({
    //       path: "/course/info/" + item.goods.relation.course_id,
    //     });
    //   }
    //   if (item.goods.type == 2 && item.goods.relation.style_temp == 0) {
    //     this.$router.push({
    //       path: "/course-package/info/" + item.goods.relation.course_package_id,
    //     });
    //   }
    //   if (item.goods.type == 2 && item.goods.relation.style_temp == 1) {
    //     this.$router.push({
    //       path:
    //         "/course/courseGroupList/" + item.goods.relation.course_package_id,
    //     });
    //   }
    //   if (item.goods.type == 3) {
    //     this.$router.push({
    //       path: "/camp/info/" + item.goods.relation.camp_id,
    //     });
    //   }
    // },
    // 获取当前时间
    intervalTime(startTime, endTime) {
      // var timestamp=new Date().getTime(); //计算当前时间戳
      var timestamp = Date.parse(new Date()) / 1000; //计算当前时间戳 (毫秒级)
      var date1 = ""; //开始时间
      if (timestamp < startTime) {
        date1 = startTime;
      } else {
        date1 = timestamp; //开始时间
      }
      var date2 = endTime; //结束时间
      // var date3 = date2.getTime() - date1.getTime(); //时间差的毫秒数
      var date3 = (date2 - date1) * 1000; //时间差的毫秒数
      //计算出相差天数
      var days = Math.floor(date3 / (24 * 3600 * 1000));
      //计算出小时数

      var leave1 = date3 % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
      var hours = Math.floor(leave1 / (3600 * 1000));
      //计算相差分钟数
      var leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
      var minutes = Math.floor(leave2 / (60 * 1000));

      //计算相差秒数

      var leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
      var seconds = Math.round(leave3 / 1000);
      console.log(days);
      // return   days + "天 " + hours + "小时 " + minutes + " 分钟" + seconds + " 秒"
      return days;
    },
  },
  created() {
    let _this = this;
    newType().then((res) => {
      console.log(res, "分类");
      var data = res.result;
      _this.typeList = data;
      console.log(_this.typeList, "32323232");
    });
    // _this.recommendModular();
    _this.loadList();
    _this.loadImg();
  },
};
</script>
<style>
.zanwu {
  margin: 0 auto;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
  color: #666666;
}
.zanwu img {
  width: 200px;
  margin: 0 auto;
}
</style>